<!-- 统计单灯实时报警类型 -->
<template>
  <div class="alarm-time-list">
    <div class="device-name">统计单灯实时报警类型</div>

    <div class="tabList">
      <chartsPie :option="pieData" id="lampPoliceBox" class="lampPolice-pie" :key="setRefresh"></chartsPie>
      <!-- <div class="tabList-box" v-for="(value, key, index) in list" :key="index">
        <div>{{key}}</div>
        <div>{{value}}个</div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QueryAlarmAnalyseType',
  components: {
  },
  data() {
    return {
      list: {},
      setRefresh: 0,
      pieData: {
        color: ['#6CCAEE', '#3379FB', '#51D59B', '#D6B981', '#F45F73'],
        legend: {
          top: '0',
          left: 'center'
        },
        series: [
          { value: 0, name: '' },
        ],
        setSeries: {
          label: {
            position: 'inside',
            formatter: '{b}:{c}'
          },
          radius: ['44%', '80%'],
          center: ['50%', '50%'],
          itemStyle: {
            borderRadius: 10,
            borderColor: 'rgba(255,255,255,1)',
            borderWidth: 2
          }
        }
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setRefresh++;
    }
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryAlarmAnalyseType`, {}).then(res => {
        if (res.code == 0) {
          let arr = Object.entries(res.data).map(([key, value]) => [key, value])
          this.$set(this, 'list', arr);
          this.handleData();
        }
      });
    },
    handleData() {
      this.pieData.series = [];
      this.list.forEach(el => {
        this.pieData.series.push({ value: el[1], name: el[0] })
        // this.pieData.series.push({ value: el[0] * 1, name: el[1] })
      });
      this.setRefresh++;
    }
  }
}
</script>
<style scoped>
.alarm-time-list {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.tabList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.lampPolice-pie {
  margin-top: 15px;
  width: 95%;
  height: 95%;
}
/* .tabList-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.tabList-box > div {
  width: 50%;
  text-align: center;
} */
</style>