<template>
  <!-- 查询照明设备统计数据 -->
  <div class="device-total">
    <div class="device-name">照明设备统计数据</div>
    <div class="mt20 device-total-box">
      <p> <img :src="img.visual.tunnel.desktop.gateway" alt="" class="device-total-icon"> 网关</p>
      <div class="device-list">
        <div class="device-box">网关总数:{{list.gatewayCount}}</div>
        <div class="device-box">在线数量:{{list.gatewayOnline}}</div>
        <div class="device-box">离线数量:{{list.gatewayOffline}}</div>
      </div>
    </div>
    <div class="mt20 device-total-box">
      <p> <img :src="img.visual.tunnel.desktop.lightControl" alt="" class="device-total-icon"> 灯控</p>
      <div class="device-list">
        <div class="device-box">灯控总数:{{list.lightCount}}</div>
        <div class="device-box">在线数量:{{list.lightOnline}}</div>
        <div class="device-box">离线数量:{{list.lightOffline}}</div>
      </div>
      <div class="device-list" style="margin-top: 2px;">
        <div class="device-box">报警数量:{{list.lightAlarm}}</div>
        <div class="device-box">开灯数量:{{list.lightRunning}}</div>
        <div class="device-box">未开灯数量:{{list.lightNotRunning}}</div>
      </div>
    </div>
    <div class="mt20 device-total-box">
      <p> <img :src="img.visual.tunnel.desktop.source" alt="" class="device-total-icon"> 光源</p>
      <div class="device-list">
        <div class="device-box">光源总数:{{list.lampCount}}</div>
        <div class="device-box">亮灯数量:{{list.lampRunning}}</div>
        <div class="device-box">灭灯数量:{{list.lampNotRunning}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QueryDeviceTotal',
  components: {
  },
  data() {
    return {
      list: {
        "gatewayCount": 0,
        "gatewayOnline": 0,
        "gatewayOffline": 0,
        "lightCount": 0,
        "lightOnline": 0,
        "lightOffline": 0,
        "lightAlarm": 0,
        "lightNotAlarm": 0,
        "lightRunning": 0,
        "lightNotRunning": 0,
        "lampCount": 0,
        "lampRunning": 0,
        "lampNotRunning": 0
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setRefresh++;
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryDeviceTotal`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    }
  }
}
</script>
<style scoped>
.device-total {
  height: 100%;
  width: 100%;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.device-total-box {
  width: 100%;
  background: #f8faff;
  border-radius: 8px;
  padding: 19px 24px 18px;
}
.device-total-box p {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  height: 28px;
  line-height: 28px;
}
.device-list {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}
.device-box {
  width: 33%;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #787f92;
}
.device-total-icon {
  width: 24px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
</style>