<template>
  <div class="module-list">
    <div class="module-box">
      <div class="module-subset">
        <QueryDeviceTotal></QueryDeviceTotal>
      </div>
      <div class="module-subset">
        <QueryDeviceCurrent></QueryDeviceCurrent>
      </div>
      <div class="module-subset">
        <QueryCurrentAlarm />
      </div>
      <div class="module-subset">
        <QueryAlarmAnalyseType />
      </div>
      <div class="module-subset">
        <QueryLightOnlineRate />
      </div>
      <div class="module-subset">
        <QueryLightOpenRate />
      </div>
      <div class="module-subset">
        <QueryLampOpenRate />
      </div>
      <div class="module-subset">
        <QueryLampEnergyTrend />
      </div>
    </div>
  </div>
</template>
<script>
import QueryDeviceTotal from './module/light/QueryDeviceTotal.vue'
import QueryCurrentAlarm from './module/light/QueryCurrentAlarm.vue'
import QueryAlarmAnalyseType from './module/light/QueryAlarmAnalyseType.vue'
import QueryLightOnlineRate from './module/light/QueryLightOnlineRate.vue'
import QueryLightOpenRate from './module/light/QueryLightOpenRate.vue'
import QueryLampOpenRate from './module/light/QueryLampOpenRate.vue'
import QueryLampEnergyTrend from './module/light/QueryLampEnergyTrend.vue'
import QueryDeviceCurrent from './module/lux/QueryDeviceCurrent.vue'
export default {
  name: 'module',
  components: {
    QueryDeviceTotal,
    QueryDeviceCurrent,
    QueryCurrentAlarm,
    QueryAlarmAnalyseType,
    QueryLightOnlineRate,
    QueryLightOpenRate,
    QueryLampOpenRate,
    QueryLampEnergyTrend,
  },
  data() {
    return {
    }
  },
  created: function () {
  },

  computed: {
  },
  watch: {
  },
  mounted: function () {


  },
  destroyed: function () {
  },
  methods: {
    init() {

    },
  },
}
</script>

<style scoped>
.module-list {
  display: flex;
  /* background-color: #e4e7ed; */
  width: 100%;
  height: 100%;
}
.module-box {
  display: flex;
  /* flex: 1 1; */
  width:calc(100%);
  height: calc(100%);
  flex-flow: wrap;
  /* flex-direction: column; */
  padding: 15px 0 0;
  justify-content: space-between;
  overflow-y: auto;
}
.module-subset {
  width: calc((100% - 72px) / 4);
  height: 430px;
  background-color: #fff;
  border-radius: 15px;
  color: chocolate;
  margin-bottom: 24px;
  padding: 24px 24px;
}
</style>