<!-- 查询单灯能耗日度趋势 -->
<template>
  <div class="alarm-time-list">
    <div class="device-name">单灯近七天能耗</div>
    <div class="tabList">
      <chartsBar :option="chartsLineData" id="chartsLineData" :key="setRefresh"></chartsBar>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QueryLampEnergyTrend',
  components: {
  },
  data() {
    return {
      list: [],
      setRefresh:0,
      chartsLineData: {
        color: ['#4ABEFF'],
        title: "",
        x: [],
        unit: "lux",
        series: [
          {
            name: "",
            data: [],
          },
        ],
        setSeries: {
          showBackground:false,
          smooth: false, //平滑折线图
          areaStyle: {}, //下阴影
          barWidth: '14px',
        },
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setRefresh++;
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      let ajaxData = {
        "start": "2024-05-01",
        "end": "2024-05-11"
      }
      let now = new Date();
      now.setDate(now.getDate());
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`device/light/QueryLampEnergyTrend`, ajaxData).then(res => {
        this.setRefresh++;
        if (res.code == 0) {
          this.$set(this, 'list',res.data);
          this.list.forEach(ele => {
            this.chartsLineData.x.push(ele.time.slice(8, 11)+'日')
            this.chartsLineData.series[0].data.push(ele.value)
          });
        }
      });
    }
  }
}
</script>
<style scoped>
.alarm-time-list {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.tabList {
  width: 98%;
  height: calc(100% - 30px);
  
}
</style>