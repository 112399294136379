<!-- 单灯实时报警 -->
<template>
  <div class="alarm-time-list">
    <div class="device-name">单灯实时报警</div>
    <div class="tabList">
      <div class="tabList-box-top">
        <div class="lampName">设备</div>
        <div class="tabList-name">报警类型</div>
        <div class="startTime">时间</div>
      </div>
      <div class="tabList-box" v-for="el,index in list" :key="index">
        <div class="lampName" :title="el.lampName">{{el.lampName}}</div>
        <div class="tabList-name">
          <span>{{el.name}}</span>
        </div>
        <div class="startTime">{{el.startTime}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QueryCurrentAlarm',
  components: {
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setRefresh++;
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryCurrentAlarm`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
        }
      });
    }
  }
}
</script>
<style scoped>
.alarm-time-list {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.tabList {
  height: calc(100% - 24px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.tabList-box-top {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
}
.tabList-box {
  display: flex;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
}
.tabList-box:nth-child(2n) {
  border-top: 1px solid #f1f5f8;
  border-bottom: 1px solid #f1f5f8;
  background: #f9fbfe;
}
.tabList-box:nth-child(2n-1) {
  background: #fff;
}
.tabList-name {
  padding: 0 5px;
  color: #333333;
}
.tabList-name span {
  background: #fde9ed;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 14px;
  color: #f53a65;
  display: inline-block;
  padding: 0 5px;
  min-width: 72px;
  border-radius: 13px;
  text-align: center;
  height: 27px;
  line-height: 27px;
}
.lampName {
  width: 100px;
  text-indent: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.startTime {
  width: 145px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
</style>