<!-- 查询光源亮灯率日度趋势 -->
<template>
  <div class="alarm-time-list">
    <div class="device-name">光源近七天亮灯率</div>
    <div class="tabList">
      <chartsLine :option="lineData" id="queryLampOpenRate" :key="setRefresh"></chartsLine>
      <!-- <div class="tabList-box" v-for="el,index in list" :key="index">
        <div>{{el.date}}</div>
        <div>{{el.rate}}%</div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import * as echarts from 'echarts';
export default {
  name: 'QueryLampOpenRate',
  components: {
  },
  data() {
    return {
      list: [],
      setRefresh:0,
      lineData: {
        color: ["#FFCC44"],
        title: "",
        x: [],
        unit: "%",
        series: [
          {
            name: "",
            data: [],
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          //下阴影
        },
        cover: {
          legend: {
            padding: [5, 10],
            top: '5%',
            data: []
          },
        }
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setRefresh++;
    }
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      let ajaxData = {
        "start": "2024-05-01",
        "end": "2024-05-11"
      }
      let now = new Date();
      now.setDate(now.getDate());
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`device/light/QueryLampOpenRate`, ajaxData).then(res => {
        this.setRefresh++;
        if (res.code == 0) {
          // let arr=[
          //   {date:'07-08',rate:55},
          //   {date:'07-09',rate:65},
          //   {date:'07-10',rate:25},
          //   {date:'07-11',rate:95},
          //   {date:'07-12',rate:15},
          // ]
          this.$set(this, 'list',res.data);
          this.list.forEach(ele => {
            this.lineData.x.push(ele.date.slice(8, 11)+'日')
            this.lineData.series[0].data.push(ele.rate)
          });
        }
      });
    }
  }
}
</script>
<style scoped>
.alarm-time-list {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.tabList {
  width: 98%;
  height: calc(100% - 30px);

}

</style>