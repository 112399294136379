<template>
  <!-- 查询照明设备统计数据 -->
  <div class="device-total">
    <div class="device-name">光照度设备统计数据</div>
    <div class="tunnel-box">
      <div class="inner">
        <p class="device-total-p"><img :src="img.visual.tunnel.desktop.tunnelWithin" alt="" class="device-total-icon">隧道内亮度</p>
        <div class="tunnel-con mt10" v-for="el,index in inner" :key="index">
          <div class="tunnel-name">{{el.name}}</div>
          <div class="tunnel-lux">{{el.lux}}Lux</div>
          <div class="tunnel-time">{{el.time}}</div>
        </div>
      </div>
      <div class="outer">
        <p class="device-total-p"><img :src="img.visual.tunnel.desktop.tunnelOutside" alt="" class="device-total-icon">隧道外照度</p>
        <div class="tunnel-con mt10" v-for="el,index in outer" :key="index">
          <div class="tunnel-name">{{el.name}}</div>
          <div class="tunnel-lux">{{el.lux}}Lux</div>
          <div class="tunnel-time">{{el.time}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QueryDeviceCurrent',
  components: {
  },
  data() {
    return {
      inner: [],
      outer: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/lux/QueryDeviceCurrent`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'inner', res.data.inner);
          this.$set(this, 'outer', res.data.outer);
        }
      });
    }
  }
}
</script>
<style scoped>
.device-total {
  height: 100%;
  width: 100%;
}
.device-name {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.device-list {
  display: flex;
  justify-content: space-evenly;
}
.device-box {
  width: 33%;
}
.tunnel-box {
  height: calc(100% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 22px;
}
.tunnel-con {
  display: flex;
  justify-content: space-between;
}
.tunnel-name {
  width: calc(100% - 250px);
  color: #787f92;
  font-size: 14px;
}
.tunnel-lux {
  width: 88px;
  text-align: left;
  padding: 0 10px;
  color: #333333;
  font-size: 14px;
}
.tunnel-time {
  width: 140px;
  color: #333333;
  font-size: 14px;
  text-align: right;
}

.inner,
.outer {
  width: 100%;
  height: 159px;
  padding: 20px;
  background: #f8faff;
  overflow-y: auto;
}
.inner .device-total-icon {
  width: 24px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  border-radius: 8px;
}
.outer .device-total-icon {
  width: 30px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
.device-total-p {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  height: 26px;
  line-height: 26px;
}
</style>